<template>
  <div class="outer-news">
    <!-- <van-sticky>
      <div class="top-outer2">
        <div class="left type-select">
          <span class="type-css">审批类型：</span>
          <van-dropdown-menu active-color="#006C50" class="drop">
            <van-dropdown-item
              v-model="valueType"
              :options="option1"
              @change="changeSubmitType"
            />
          </van-dropdown-menu>
        </div>
        <div class="left">
          <span class="type-css">审批状态：</span>
          <van-dropdown-menu active-color="#006C50" class="drop">
            <van-dropdown-item
              v-model="value1"
              :options="option2"
              @change="changeSubmitType"
            />
          </van-dropdown-menu>
        </div>
    
      </div>
    </van-sticky> -->

    <div class="mlist">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          class="van-clearfix"
          v-model="loading"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          :finished="finished"
          finished-text=""
          @load="onLoad"
        >
          <template v-if="dataList.length > 0">
            <template v-for="(item, index) in dataList">
              <div class="groups-outer" :key="index">
                <div class="item" @click="toNotify(item)">
                  <!-- <div class="dot" v-if="!item.readed"></div> -->
                  <div class="con">
                    <div class="title-msg">
                      <template v-if="!item.readed">
                        <img
                          src="../../assets/image/n15.png"
                          v-if="item.type == 'SUCCESS'"
                        />
                        <img
                          src="../../assets/image/n17.png"
                          v-else-if="item.type == 'WARNING'"
                        />
                        <img
                          src="../../assets/image/n18.png"
                          v-else-if="item.type == 'ERROR'"
                        />
                        <img src="../../assets/image/n16.png" v-else />
                      </template>
                      <template v-else>
                        <img
                          src="../../assets/image/n11.png"
                          v-if="item.type == 'SUCCESS'"
                        />
                        <img
                          src="../../assets/image/n13.png"
                          v-else-if="item.type == 'WARNING'"
                        />
                        <img
                          src="../../assets/image/n14.png"
                          v-else-if="item.type == 'ERROR'"
                        />
                        <img src="../../assets/image/n12.png" v-else />
                      </template>

                      <div class="title-text">{{ item.title }}</div>
                    </div>
                    <div class="detail-msg">
                      {{ item.content }}
                    </div>
                    <div class="time-outer">
                      <div class="time">
                        <img src="../../assets/image/time.png" class="img" />
                        <span class="txt">{{ item.createTime }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <div class="no-data">
              <img src="../../assets/image/n5.png" class="img" />
              <span class="txt">暂无内容</span>
            </div>
          </template>
        </van-list>
      </van-pull-refresh>
    </div>
    <el-drawer
      :size="'100%'"
      direction="rtl"
      title="审批详情"
      :visible.sync="processVisible"
    >
      <!-- <instance-preview
        v-if="processVisible"
        :node-id="selectInstance.nodeId"
        :instance-id="selectInstance.instanceId"
        @handler-after="handlerAfter"
      ></instance-preview> -->
      <instance-preview
        v-if="processVisible"
        :instance-id="selectInstance"
        :node-id="nodeId"
        @handler-after="processVisible = false"
      />
    </el-drawer>
  </div>
</template>

<script>
import InstancePreview from "./approval/ProcessInstancePreview";
import { getUserNotify, readNotify } from "@/api/notify";

export default {
  name: "MySubmit",
  components: { InstancePreview },
  data() {
    return {
      total: 0,
      valueSearch: "",
      value1: 0,
      valueType: 0,
      error: false,
      value2: 0,
      result: [],
      finished: false,
      refreshing: false,
      showDate: false,
      option1: [],
      option2: [
        { text: "全部", value: 0 },
        { text: "执行中", value: 1 },
        { text: "已结束", value: 2 },
      ],

      params: {
        pageSize: 10,
        pageNo: 0,
        // finished: null,
        // code: "",
      },
      openItemDl: false,
      selectInstance: null,
      nodeId: null,
      loading: false,
      processVisible: false,
      formList: [],
      dataList: [],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    closeDetail() {
      //this.processVisible = false;
      this.dataList = [];
      // this.refreshing = false;
      this.params.pageNo = 1;
      this.requestData();
    },
    toNotify(msg) {
      if (this.$isNotEmpty(msg.instanceId)) {
        this.selectInstance = msg.instanceId;
        this.nodeId = msg.nodeId;
        // alert(msg.instanceId);
        this.processVisible = true;
        this.readNotify(msg.id);
      }
    },
    readNotify(id) {
      let list = id ? [id] : this.dataList.records.map((n) => n.id);
      readNotify(list)
        .then((rsp) => {
          this.$ok(rsp, "已读成功");
          this.closeDetail();
          // this.onRefresh();
        })
        .catch((err) => {
          this.$err(err, "已读失败");
        });
    },
    changeSubmitType() {
      this.dataList = [];

      this.params.pageNo = 1;
      this.getSubmittedList();
    },
    onRefresh() {
      // 清空列表数据
      // console.log("刷新了")
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
        this.params.pageNo = 1;
      } else {
        //this.dataList = [];
        //加载下一页面的时候改下起始页数
        this.params.pageNo = this.params.pageNo + 1;
        if (this.error) {
          this.params.pageNo = 1;
        }
      }
      //  console.log("上拉加载数据之前看看参数"+JSON.stringify(this.params));
      this.requestData();
    },

    requestData() {
      let paramsObj = {
        ...this.params,
        // formName: this.valueType > 0 ? this.option1[this.valueType].text : "", //审批类型
        //formName:this.option1[this.valueType].text,//审批类型
        // finished: this.value1, //审批状态
      };
      getUserNotify(paramsObj)
        .then((rsp) => {
          console.log(rsp);
          this.loading = false;
          this.total = rsp.data.page.total;
          this.dataList = [...this.dataList, ...rsp.data.page.records];
          //console.log("还有下一页没看看当前长度"+this.dataList.length+"`总长度```"+ this.total)
          if (this.dataList.length < this.total) {
          } else {
            //  console.log("大于总数啊，没有下一页了")
            this.finished = true;
          }
        })
        .catch((err) => {
          this.error = true;
        });
    },
  },
};
</script>
<style scoped lang="less">
.btns {
  display: flex;
  justify-content: flex-end;
  .el-button--text {
    background-color: #006c50;
    color: #fff;

    padding: 0.5rem 0.7rem;
    font-size: 1.3rem;
    text-align: center;
    border-radius: 0.5rem;
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 28vh;
  .img {
    width: 30vw;
  }
  .txt {
    margin-top: 1.3rem;
    color: #999;
    font-size: 1.4rem;
  }
}
.type-select {
  /deep/ .van-dropdown-menu__title {
    width: 12rem;
  }
}
.outer-news {
  width: 100%;
  position: relative;
  /deep/ .van-checkbox__icon {
    font-size: 2rem;
  }
  /deep/ .van-calendar__footer {
    .van-button {
      width: 100%;
    }
  }
  /deep/.van-dropdown-menu__bar {
    box-shadow: none !important;
    background-color: #fff;
  }
  /deep/ .el-drawer__header {
    font-size: 1.6rem !important;
    color: #333;
  }
  /deep/ .el-drawer__close-btn {
    font-size: 1.6rem !important;
    color: #333;
  }
  /deep/ .van-dropdown-menu__title {
    font-size: 1.3rem;
  }
  /deep/ .van-search {
    padding: 0;
    .van-icon {
      font-size: 1.5rem;
    }
  }
  /deep/ .van-cell {
    line-height: 2.3rem;
    font-size: 1.3rem;
  }
  /deep/ .van-dropdown-menu__bar {
    height: 3rem;
  }
  /deep/ .van-sticky--fixed {
    background-color: #fff;
  }
  .groups-outer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .top-outer {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    padding: 0 2rem;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;

    .search {
      width: 50vw;
    }
    .right {
      font-size: 1.3rem;
    }
    .more-submit {
      padding: 1rem 1.5rem;
      background-color: #006c50;
      color: #fff;
      font-size: 1.3rem;
      border-radius: 2rem;
    }
  }
  .top-outer2 {
    margin-top: 1rem;
    display: flex;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    font-size: 1.3rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    .left {
      display: flex;
      align-items: center;
      .type-css {
        padding-bottom: 0.18rem;
      }
    }
  }
  .mlist {
    width: 100%;
    padding: 2rem;

    /deep/ .van-checkbox-group {
      .van-checkbox__icon {
        margin-right: 1rem;
      }
      .van-checkbox__label {
        flex: 1;
      }
    }
    .item {
      padding: 0.8rem;
      width: 100%;
      background-color: #fff;
      border-radius: 1.5rem;
      box-sizing: border-box;
      box-shadow: 0px 0.2rem 1rem rgba(1, 12, 23, 0.06);
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1.5rem;
      position: relative;
      .dot {
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background-color: red;
      }
      .con {
        flex: 1;
        margin-left: 1rem;

        .title-msg {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 3.2rem;
          }
          .title-text {
            font-size: 1.4rem;
            color: #333;
            font-weight: 600;
            margin-left: 0.8rem;
          }
        }
        .detail-msg {
          font-size: 1.3rem;
          color: #999;
          margin-top: 0.6rem;
        }
        .time-outer {
          display: inline-block;
          display: flex;
          align-items: center;
          margin-top: 0.6rem;
          .time {
            background-color: #f6f6f6;
            border-radius: 1rem;
            display: flex;
            align-items: center;
            padding: 0.3rem 0.6rem;

            .txt {
              font-size: 1.1rem;
              color: #999999;
            }
            .img {
              width: 1.6rem;
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
}
</style>

